import './Footer.css';

function Footer() {
    return (
        <>
          <span id="contact-us-mobile" style={{visibility: 'hidden'}}></span>
          <footer className="py-3"  id="contact-us">
            <p id='logo'>SC consulting</p>
            <p>(236) 865 2876</p>
            <p>scconsulting@gmail.com</p>
            <p id='copyright'>©2023 by SC consulting</p>
          </footer>
        </>
    );
}

export default Footer;