import './HomePage.css';

import {Container, Row, Col, Image} from 'react-bootstrap';

import MainImage from '../assets/MainImage.webp';
import MainSection2Image from '../assets/MainSection2Image.jpg';
import MainSection4Image from '../assets/MainSection4Image.jpg';
import MainSection5_businessLawyers from '../assets/MainSection5_businessLawyers.jpeg';
import MainSection5_CPA from '../assets/MainSection5_CPA.jpg';

import MainSection5_constructor from '../assets/MainSection5_constructor.jpg';
import MainSection5_immigration from '../assets/MainSection5_immigration.jpg';

function HomePage() {
  return (
    <>
    <Container className="main">
      <Row className="section1">
        <Col>
          <div className="main-image-container">
            <Image id="main-image" src={MainImage} fluid />
            <div className="overlay">
              <div className="overlay-container">
                <h1 className="overlay-title">
                  Unlocking Your Potential,<br/>
                  One Strategy at a Time
                </h1>
                <div className="overlay-button-container">
                  <a className="overlay-button" href="#contact-us">
                      Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* Section2: About Us */}
      <span id="about-us-mobile" style={{visibility: 'hidden'}}></span>
      <Row className="section2" id="about-us">
        <Col>
          <Image src={MainSection2Image} fluid />
        </Col>
        <Col className="section2-text-container">
          <div>
            <h2>
            About Us, SC consulting
            </h2>
          </div>
          <div className="text-container">
            <p>
            We are SC Consulting, a pioneering force in the restaurant and cafe industry, offering fresh ideas to our clients with a trendy touch. While we have a strong presence in the food and beverage industry, our expertise spans across multiple domains, including retail, hospitality, and more. Whether you're looking to enhance your customer experience, streamline your operations, or develop a unique brand identity, SC Consulting is here to provide tailored strategies and creative insights that will help you achieve your goals.
            </p>
          </div>
        </Col>
      </Row>
      {/* Section3: Service  */}
      <span id="service-mobile" style={{visibility: 'hidden'}}></span>
      <Row className="section3" id="service">
          <div>
            <h2>
            Service
            </h2>
          </div>
          <div className="line-container">
            <div className="horizontal-line">
            </div>
          </div>
        <Row>
          <Col>
            <h3>
              Basic
            </h3>
            <div>
              <span>Business Concept Building</span>
              <span>Search for Location</span>
              <span>Target Audience Analysis</span>
              <span>Management Training</span>
              <span>Construction Guiding</span>
              {[...Array(7)].map((_, index) => (
                <span key={index}>-</span>
              ))}
            </div>
          </Col>
          <Col>
            <h3>
              Standard
            </h3>
            <div>
              <span>Business Concept Building</span>
              <span>Search for Location</span>
              <span>Target Audience Analysis</span>
              <span>Management Training</span>
              <span>Construction Guiding</span>
              <span>Social Media Building</span>
              <span>Menu & Pricing</span>
              <span>Google Business Setup</span>
              {[...Array(4)].map((_, index) => (
                <span key={index}>-</span>
              ))}
            </div>
          </Col>
          <Col>
            <h3>
              Premium
            </h3>
            <div>
              <span>Business Concept Building</span>
              <span>Search for Location</span>
              <span>Target Audience Analysis</span>
              <span>Management Training</span>
              <span>Construction Guiding</span>
              <span>Social Media Building</span>
              <span>Menu & Pricing</span>
              <span>Google Business Setup</span>
              <span>HR Service</span>
              <span>Marketing</span>
              <span>Website Design & Developing</span>
              <span>Financial Planning</span>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="pricing-button">
            <a href='#contact-us'>Pricing</a>
          </div>
        </Row>
      </Row>
      {/* Section4: How We Work */}
      <Row className="section4">
        <Col className="section4-container">
          <Image src={MainSection4Image} fluid />
          <div className="section4-overlay">
              <div className="section4-overlay-container">
                <h2 className="section4-overlay-title">
                  How We Work
                </h2>
                <p>
                  How we consult the business
                </p>
              </div>
            </div>
        </Col>
        <Col className="section4-text-container">
          <h4> 
          In all our business consulting endeavors, we follow a systematic process and employ analytical methodologies to provide comprehensive guidance.
          </h4>
          <div className="text-container">
              <p>
              1. Enhanced Financial Management
              </p>
              <p>
              2. Precise Pricing Strategies
              </p>
              <p>
              3. Google Business Setup and Management
              </p>
              <p>
              4. Tailored Marketing Solutions
              </p>
              <p>
              5. Streamlined Operations
              </p>
          </div>
        </Col>
      </Row>
      {/* Section5: Partner Program */}
      <span id="partner-program-mobile" style={{visibility: 'hidden'}}></span>
      <Row className="section5 mb-5" id="partner-program">
        <div>
          <h2>
          Partner Program
          </h2>
        </div>
        <div>
          <p>
          Be a part of our expanding network and explore the exclusive perks and advantages that are in store for you.
          </p>
        </div>
        <Row className="gray-layout-container">
          <Row className="gray-layout-image-container"> 
            <Col>
              <div>
                <div>
                <Image src={MainSection5_businessLawyers} fluid />
                </div>
                <div className="box-container">
                  <div className="box">
                    <span className="title">Bussiness Lawyers</span>
                    <p>Establishment of Corporation</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div>
              <Image src={MainSection5_CPA} fluid />
              </div>
              <div className="box-container">
                <div className="box">
                  <span className="title">Bussiness CPA</span>
                  <p>Accounting management</p>
                </div>
              </div>
            </Col>
            <Col>
              <div>
              <Image src={MainSection5_constructor} fluid />
              </div>
              <div className="box-container">
                <div className="box">
                  <span className="title">Constructor</span>
                  <p>Renovation expert</p>
                </div>
              </div>
            </Col>
            <Col>
              <div>
              <Image src={MainSection5_immigration} fluid />
              </div>
              <div className="box-container">
                <div className="box">
                  <span className="title">Immigration Consultant</span>
                  <p>Immigration program & LMIA</p>
                </div>
              </div>
            </Col>
            </Row>
          </Row>
      </Row>
      {/* Section6: What our client */}
      <Row className="section6">
        <div>
          <div className="text-container">
            <h2>
              What Our Clients Say
            </h2>
            <div>
              <span>
              " SC Consulting doesn't just provide consultancy; they become your partners in success. Their attention to detail, clear communication, and commitment to delivering results have made them an invaluable asset to our organization. "
              </span>
            </div>
            <div className="text-name">
              <span>-  Madison Millar, outtake</span>
            </div>
          </div>
        </div>
      </Row>
    </Container>
    </>
  );
}

export default HomePage;