import { useState } from 'react';
import { Navbar, Container, Nav, Offcanvas, } from 'react-bootstrap';
import './Header.css';

function Header() {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleNavClick = (e, baseTargetId) => {
    e.preventDefault();
    const targetId = window.innerWidth <= 768 ? `${baseTargetId}-mobile` : baseTargetId;
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const navbarHeight = document.querySelector('.fixed-top').offsetHeight;
      const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

      setExpanded(false);
      requestAnimationFrame(() => { 
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      });
    }
  };

  return (
    <>
      <Navbar expand="lg" className="fixed-top bg-body-tertiary mb-3">
        <Container fluid>
          <Navbar.Brand id="logo" href="/">SC consulting</Navbar.Brand>
          <Navbar.Toggle 
            aria-controls={`offcanvasNavbar-expand-lg`} 
            onClick={toggleExpanded}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="top"
            show={expanded}
            onHide={toggleExpanded}
          >
            <Offcanvas.Header closeButton onHide={() => toggleExpanded}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="ms-auto menu justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
                <Nav.Link href="#about-us" onClick={(e) => handleNavClick(e, 'about-us')} >About Us</Nav.Link>
                <Nav.Link href="#service" onClick={(e) => handleNavClick(e, 'service')} >Our service</Nav.Link>
                <Nav.Link href="#partner-program" onClick={(e) => handleNavClick(e, 'partner-program')}>Partner program</Nav.Link>
                <Nav.Link href="#contact-us" onClick={(e) => handleNavClick(e, 'contact-us')}>Contact Us</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
  </>
  );
}

export default Header;