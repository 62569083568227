import './App.css';
import Header from "./layouts/Header"
import Footer from "./layouts/Footer"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/HomePage"

import '@fontsource/gloock';
import '@fontsource/cormorant-garamond';

function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutUs" element={<HomePage />} />
          <Route path="/ourService" element={<HomePage />} />
          <Route path="/partnerProgram" element={<HomePage />} />
          <Route path="/contactUs" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
